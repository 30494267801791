import React, { useReducer, createContext } from 'react'
import jwtDecode from 'jwt-decode'

const initialState = {
  user: null,
  darkMode: false
}

if(localStorage.getItem('jwtToken')){
  const decodedToken = jwtDecode(localStorage.getItem('jwtToken'))

  if(decodedToken.exp * 1000 < Date.now()){
    localStorage.removeItem('jwtToken')
  } else {
    initialState.user = decodedToken
  }
}

if(localStorage.getItem('darkMode')){
  const darkMode = (localStorage.getItem('darkMode'))

  if(darkMode == "yes"){
    initialState.darkMode = true
  } else {
    initialState.darkMode = false
  }
} else {
  localStorage.setItem('darkMode', false)
}

const AuthContext = new createContext({
  user: null,
  login: (userData) => {},
  logout: () => {}
})

const authReducer = (state, action) => {
  switch(action.type){
    case 'LOGIN':
      return {
        ...state,
        user: action.payload
      }
    case 'LOGOUT':
      return {
        ...state,
        user: null
      }
    case 'SETDARKMODE':
      return {
        ...state,
        darkMode: action.payload
      }
    default:
      return state;
  }
}

const AuthProvider = (props) => {
  const [state, dispatch] = useReducer(authReducer, initialState)

  const login = (userData) => {
    localStorage.setItem('jwtToken', userData.token)
    dispatch({
      type: 'LOGIN',
      payload: jwtDecode(userData.token)
    })
  }

  const logout = () => {
    localStorage.removeItem('jwtToken')
    dispatch({ type: 'LOGOUT' })
  }

  const setDarkMode = () => {
    localStorage.setItem('darkMode', !state.darkMode)
    dispatch({
      type: 'SETDARKMODE',
      payload: !state.darkMode
    })
  }

  return (
  <div className={state.darkMode ? "flex flex-col h-screen max-h-screen dark" : "flex flex-col h-screen max-h-screen"}>
    <AuthContext.Provider
      value={{ user: state.user, darkMode: state.darkMode, login, logout, setDarkMode }}
      {...props}
      />
  </div>
  )
}

export { AuthContext, AuthProvider }