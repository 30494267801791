import React, { useContext, useEffect } from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import gql from 'graphql-tag'
import { useLazyQuery } from '@apollo/react-hooks'
import _ from 'lodash'
import { injectIntl } from 'react-intl'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import { AuthContext } from '../../context/auth'


const ManageSideBar = (props) => {
  const { user, darkMode, logout, setDarkMode } = useContext(AuthContext)

  const translate = (id) => {
    return props.intl.messages[id]
  }

  const sidenavLinks = [
      {name: translate('me.dashboard'), href: '/manage'},
      {name: translate('me.activityQueue'), href: '/manage/activity'},
      {name: translate('me.myAddons'), href: '/manage/addons'},
      {name: 'Pages', href: '/manage/pages'},
      {name: 'Examples', href: '/manage/examples'},
      {name: 'Translations', href: '/manage/translations'},
  ]

  var sideNavBar = (
    <nav className="flex-none bg-skugray-900 overflow-y-auto hidden sm:block text-white flex flex-col">
      {sidenavLinks.map((sidenavLink) => <Link to={sidenavLink.href} className={props.location.pathname === sidenavLink.href ? "block px-12 py-3 font-semibold bg-skured-500" : "block px-12 py-3 font-semibold hover:bg-skugray-800"}>{sidenavLink.name}</Link>)}
    </nav>
  )

  return sideNavBar
}

export default withRouter(injectIntl(ManageSideBar))