import gql from 'graphql-tag'

const MY_ADDONS = gql`
  {
    getMyAddons {
      id
      name
      author
      collaborators
      hidden
      docs {
        type
      }
    }
  }
`

const GET_ADDON = gql`
  query getAddonByName($name: String!) {
    getAddonByName(name: $name){
      id
      name
      author
      collaborators
      hidden
      docs {
        name
        type
      }
    }
  }
`

const MY_PARSES = gql`
  {
    getMyAddons {
      id
      name
      author
      collaborators
    }
  }
`

export { MY_ADDONS, GET_ADDON, MY_PARSES }