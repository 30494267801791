import React from 'react'
import Helmet from 'react-helmet'
import { injectIntl } from 'react-intl'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faMedal } from '@fortawesome/free-solid-svg-icons'

const Donate = (props) => {
  const translate = (id) => {
    return props.intl.messages[id]
  }

  return (
    <div id="Donate" className="flex-1 overflow-auto">
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content="skUnity Donate" />
      </Helmet>
      <div className="container my-16 px-5 grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <p className="md:text-5xl font-bold text-skugray-900 text-4xl w-full">About <span className="text-skured-500">sk</span>Unity</p>
          <p className="md:text-lg text-skugray-900 text-md w-full"><FontAwesomeIcon className="text-1xl text-skured-500" icon={faAngleDoubleRight} /> Since 2014 skUnity has provided documentation for the Skript community powering addon documentation and keeping Skript documentation up to date</p>
          <br />
          <p className="md:text-lg text-skugray-900 text-md w-full"><FontAwesomeIcon className="text-1xl text-skured-500" icon={faAngleDoubleRight} /> The central hub of everything Skript, skUnity has empowered the Skript community to continue to create, share and work with others on their projects</p>
          <br />
          <p className="md:text-lg text-skugray-900 text-md w-full"><FontAwesomeIcon className="text-1xl text-skured-500" icon={faAngleDoubleRight} /> The largest social platforms for the Skript community with the <a href="https://forums.skunity.com" className="text-skured-500">skUnity Forums</a> and <a href="https://discrod.gg/0l3WlzBPKX7WNjkf" className="text-skured-500">skUnity Discord</a> bringing people together, supporting each other and meeting new people</p>
          <br />
          <p className="md:text-lg text-skugray-900 text-md w-full"><FontAwesomeIcon className="text-1xl text-skured-500" icon={faAngleDoubleRight} /> A unique set of tools for the Skript community: skUnity Parser for Skripting online, skUnity Resources being the only and central repository for Skript content and the largest Skript documentation site, skUnity Docs.</p>
        </div>
        <div>
          <p className="md:text-5xl font-bold text-skugray-900 text-4xl w-full">Supporting Us</p>
          <p className="md:text-lg text-skugray-900 text-md w-full">skUnity provides a lot of services to the Skript community and these services cost us money. Support skUnity to help us continue providing these amazing services and continue to make more new and exciting things.</p>
          
          <br />
          <p className="md:text-lg text-skugray-900 text-md w-full">If you have an account on the <a href="https://forums.skunity.com">skUnity Forums</a>, you can donate there using the <a className="text-skured-500" href="https://forums.skunity.com/account/upgrades">Account Upgrades</a>. You can make a donation directly to payments@billing.skunity.com or using <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=H9Q5CR6PCPYHC&source=url">this link to donate via PayPal</a> or the button below</p>
          <br />
          <div class="flex justify-center">
            <form action="https://www.paypal.com/donate" method="post" target="_top">
              <input type="hidden" name="hosted_button_id" value="44REBH98QV282" />
              <input type="image" src="https://www.paypalobjects.com/en_US/GB/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
              <img alt="" border="0" src="https://www.paypal.com/en_GB/i/scr/pixel.gif" width="1" height="1" />
            </form>
          </div>
          <div class="flex justify-center">
            <p className="md:text-sm text-skugray-900">Donate directly to skUnity via PayPal</p>
          </div>
        </div>
      </div>
      <div className="container my-16 px-5 grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <p className="md:text-5xl font-bold text-skugray-900 text-4xl w-full">Donation Rewards</p>
          <p className="md:text-lg text-skugray-900 text-md w-full">As a thank you for donating to skUnity, we give you some rewards on our services!</p>
          <p className="md:text-sm text-skugray-900 text-md w-full">Rewards are given to donations of $5 and above.</p>
          <br />
          <p className="md:text-lg text-skugray-900 text-md w-full"><FontAwesomeIcon className="text-1xl text-skured-500" icon={faMedal} /> Supporter tag on the skUnity Forums</p>
          <br />
          <p className="md:text-lg text-skugray-900 text-md w-full"><FontAwesomeIcon className="text-1xl text-skured-500" icon={faMedal} /> skUnity Donator role on the skUnity Discord with access to #exclusive-chat</p>
          <br />
          <p className="md:text-lg text-skugray-900 text-md w-full"><FontAwesomeIcon className="text-1xl text-skured-500" icon={faMedal} /> Exclusive discounts with skHosting</p>
          <br />
          <p className="md:text-lg text-skugray-900 text-md w-full"><FontAwesomeIcon className="text-1xl text-skured-500" icon={faMedal} /> Priority Parsing on the skUnity Parser</p>
        </div>
      </div>
      <div className="container">
        <div>
          <p className="md:text-5xl font-bold text-skugray-900 text-4xl w-full">Promoting skUnity</p>
          <p className="md:text-lg text-skugray-900 text-md w-full">You can help skUnity out in other ways by setting your signature on other forums to the ones below! Copy the BBCode and paste it as you're signature. This will let others know about skUnity and grow our community! </p>
          <p className="md:text-sm text-skugray-900 text-md w-full">Be sure to check the rules of each forum to make sure you're allowed to do this</p>

          <p className="md:text-3xl font-bold text-skugray-900 text-4xl w-full pt-5">Mixed text and images (2 lines, 4 links and 2 images). SpigotMC supported</p>
          <div className="w-full p-4 bg-skugray-100" style={{height: '200px'}}>
          [center]
          [img]https://docs.skunity.com/img/forums/black/sku-logo.png[/img]
          [url="https://docs.skunity.com"][FONT=Verdana][SIZE=7][B]Docs[/B][/SIZE][/FONT][/url] [B][FONT=Verdana][SIZE=7]/[/SIZE][/FONT][/B] [url="https://forums.skunity.com"][B][FONT=Verdana][SIZE=7]Forums[/SIZE][/FONT][/B][/url] [B][FONT=Verdana][SIZE=7]/[/SIZE][/FONT][/B] [url="https://parser.skunity.com"][B][FONT=Verdana][SIZE=7]Parser[/SIZE][/FONT][/B][/url] [FONT=Verdana][SIZE=7][B]/[/B][/SIZE][/FONT][url="https://discord.gg/0l3WlzBPKX7WNjkf"][B][FONT=Verdana][SIZE=7]Discord[/SIZE][/FONT][/B][/url]
          [/center]
          </div>

          <p className="md:text-3xl font-bold text-skugray-900 text-4xl w-full pt-5">Black text images</p>
          <div className="w-full p-4 bg-skugray-100" style={{height: '200px'}}>
          [center]
          The Skript community for everyone. 
          Forums, Docs, the Parser, Discord and more... Join skUnity
          [url="https://skunity.com"][img]https://docs.skunity.com/img/forums/black/sku-logo.png[/img][/url][br]
          [url="https://docs.skunity.com"][img]https://docs.skunity.com/img/forums/black/docs.png[/img][/url] [img]https://docs.skunity.com/img/forums/split.png[/img] [url="https://forums.skunity.com"][img]https://docs.skunity.com/img/forums/black/forums.png[/img][/url] [img]https://docs.skunity.com/img/forums/split.png[/img] [url="https://parser.skunity.com"][img]https://docs.skunity.com/img/forums/black/parser.png[/img][/url] [img]https://docs.skunity.com/img/forums/split.png[/img] [url="https://discord.gg/0l3WlzBPKX7WNjkf"][img]https://docs.skunity.com/img/forums/black/discord.png[/img][/url]
          [/center]
          </div>

          <p className="md:text-3xl font-bold text-skugray-900 text-4xl w-full pt-5">White text images</p>
          <div className="w-full p-4 bg-skugray-100" style={{height: '200px'}}>
          [center]
          The Skript community for everyone. 
          Forums, Docs, the Parser, Discord and more... Join skUnity
          [url="https://skunity.com"][img]https://docs.skunity.com/img/forums/white/sku-logo.png[/img][/url][br]
          [url="https://docs.skunity.com"][img]https://docs.skunity.com/img/forums/white/docs.png[/img][/url] [img]https://docs.skunity.com/img/forums/white/split.png[/img] [url="https://forums.skunity.com"][img]https://docs.skunity.com/img/forums/white/forums.png[/img][/url] [img]https://docs.skunity.com/img/forums/white/split.png[/img] [url="https://parser.skunity.com"][img]https://docs.skunity.com/img/forums/white/parser.png[/img][/url] [img]https://docs.skunity.com/img/forums/white/split.png[/img] [url="https://discord.gg/0l3WlzBPKX7WNjkf"][img]https://docs.skunity.com/img/forums/white/discord.png[/img][/url]
          [/center]
          </div>

        </div>
      </div>
    </div>
  )
  
}
export default injectIntl(Donate)