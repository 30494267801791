import React, { useReducer, createContext } from 'react'
import _ from 'lodash'

const initialState = {
  docs: null
}

if(localStorage.getItem('docs')){
  initialState.docs = JSON.parse(localStorage.getItem('docs'))
}

const DocsContext = new createContext({
  docs: null,
  setDocs: (docsData) => {}
})

const docsReducer = (state, action) => {
  switch(action.type){
    case 'SET':
      return {
        ...state,
        docs: action.payload
      }
    default:
      return state;
  }
}

const DocsProvider = (props) => {
  const [state, dispatch] = useReducer(docsReducer, initialState)

  const setDocs = (docsData) => {
    localStorage.setItem('docs', JSON.stringify(_.sortBy(docsData, ['id'])))
    dispatch({
      type: 'SET',
      payload: docsData
    })
  }

  return (
    <DocsContext.Provider
      value={{ docs: state.docs ? state.docs[0] : null, setDocs }}
      {...props}
      />
  )
}

export { DocsContext, DocsProvider }