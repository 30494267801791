import Home from './Home'
import Docs from './Docs'
import Login from './Login'
import Skript from './Skript'
import Parser from './Parser'
import Donate from './Donate'

import Manage from './manage/index'
import Admin from './admin/index'
import Legal from './legal/index'

import Error404 from './Error404'

const Pages = {
	Home,
	Docs,
	Login,
	Skript,
	Parser,
	Donate,
	Manage,
	Admin,
	Legal,
	Error404
}

export default Pages