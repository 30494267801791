import React, { useContext } from 'react'
import Helmet from 'react-helmet'
import { injectIntl } from 'react-intl'
import { AuthContext } from '../../context/auth'

const Cookies = (props) => {
  const context = useContext(AuthContext)
  const translate = (id) => {
    return props.intl.messages[id]
  }
  return (
    <div id="Cookies" className="flex-1 overflow-auto">
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content="skUnity Me" />
      </Helmet>
      <div className="bg-skugray-900">
        <div className="container pt-16 pb-12 px-5">
          <h1 className="md:text-5xl font-bold text-white text-4xl">Cookies Policy</h1>
          <p className="text-skugray-400 pt-2 pl-4">Last updated: 7th of December 2020</p>
        </div>
      </div>

      <div className="container px-12 mt-12 space-y-4 max-w-6xl mb-32">
        This page discusses how cookies are used by this site. If you continue to use this site and our services, you are consenting to our use of cookies.
        <h2 className="font-semibold text-2xl my-4 text-skured-500">What are cookies?</h2>
        <p>Cookies are small text files stored on your computer by your web browser at the request of a site you're viewing. This allows the site you're viewing to remember things about you, such as your preferences and history or to keep you logged in.</p>
        <p>Cookies may be stored on your computer for a short time (such as only while your browser is open) or for an extended period of time, even years. Cookies not set by this site will not be accessible to us.</p>

        <h2 className="font-semibold text-2xl my-4 text-skured-500">Our cookie usage</h2>
        <p>This site and our services use cookies for numerous things, including:</p>
        <ul className="list-inside list-disc">
          <li><span className="font-semibold">Registration and maintaining your preferences.</span> This includes ensuring that you can stay logged in and keeping the site in the language or appearance that you requested.</li>
          <li><span className="font-semibold">Analytics.</span> This allows us to determine how people are using the site and improve it.</li>
          <li><span className="font-semibold">Security.</span> Such as Cloudflare utilises cookies to provide security to the site</li>
          <li><span className="font-semibold">Other third-party cookies</span> for things like Facebook or Twitter sharing. These cookies will generally be set by the third-party independently, so this site will have no ability to access them.</li>
        </ul>

        <h2 className="font-semibold text-2xl my-4 text-skured-500">Standard cookies we set</h2>
        <p>These are the main cookies we set during normal operation of the software.</p>
        <div className="divide-y divide-skugray-200 divide-solid">
          <div className="divide-x divide-skugray-200 divide-solid grid grid-cols-4">
            <div className="col-span-1 bg-skugray-200 p-2 font-semibold">Cookie</div>
            <div className="col-span-1 bg-skugray-200 p-2 font-semibold">Name</div>
            <div className="col-span-2 bg-skugray-200 p-2 font-semibold">Purpose</div>
          </div>

          <div className="divide-x divide-skugray-200 divide-solid grid grid-cols-4">
            <div className="col-span-1 bg-skugray-100 p-2">Cookie Preferences</div>
            <div className="col-span-1 bg-skugray-100 p-2">cookieconsent_status</div>
            <div className="col-span-2 bg-skugray-100 p-2">Remembering a user's acceptance about cookies on skunity.com</div>
          </div>

          <div className="divide-x divide-skugray-200 divide-solid grid grid-cols-4">
            <div className="col-span-1 bg-skugray-100 p-2">Cloudflare</div>
            <div className="col-span-1 bg-skugray-100 p-2">__cfduid</div>
            <div className="col-span-2 bg-skugray-100 p-2">
              <p>Providing security for the website and services</p>
              <p>Applies to sub-domains such as mc.skuhosting.com and others</p>
            </div>
          </div>

          <div className="divide-x divide-skugray-200 divide-solid grid grid-cols-4">
            <div className="col-span-1 bg-skugray-100 p-2">skUnity</div>
            <div className="col-span-1 bg-skugray-100 p-2">
              <p>darkMode</p>
              <p>models</p>
              <p>docs</p>
            </div>
            <div className="col-span-2 bg-skugray-100 p-2">Providing the best experience while using skUnity services such as Parser or Docs, preventing your browser from downloading all the data everytime you access the site.</div>
          </div>

          <div className="divide-x divide-skugray-200 divide-solid grid grid-cols-4">
            <div className="col-span-1 bg-skugray-100 p-2">skUnity Auth</div>
            <div className="col-span-1 bg-skugray-100 p-2">
              <p>xf_session</p>
              <p>xf_tfa_trust</p>
              <p>jwtToken</p>
            </div>
            <div className="col-span-2 bg-skugray-100 p-2">Keeping you logged in, providing a secure connection between you and the server and to perform adminstrative actions</div>
          </div>
          
          <div className="divide-x divide-skugray-200 divide-solid grid grid-cols-4">
            <div className="col-span-1 bg-skugray-100 p-2">Universal Analytics (Google)</div>
            <div className="col-span-1 bg-skugray-100 p-2">
              <p>_ga</p>
              <p>_gali</p>
              <p>_gat</p>
              <p>_gid</p>
            </div>
            <div className="col-span-2 bg-skugray-100 p-2">Purpose: these cookies are used to collect information about how visitors use our website. We use the information to compile reports and to help us improve the website. The cookies collect information in a way that does not directly identify anyone, including the number of visitors to the website and blog, where visitors have come to the website from and the pages they visited. <a target="_blank" className="sku-link" href="https://support.google.com/analytics/answer/6004245">Read Google's overview of privacy and safeguarding data</a>.</div>
          </div>
        </div>

        <h2 className="font-semibold text-2xl my-4 text-skured-500">Additional cookies and those set by third parties</h2>
        <p>Additional cookies may be set during the use of the site to remember information as certain actions are being performed, or remembering certain preferences.</p>
        <p>Other cookies may be set by third party service providers which may provide information such as tracking anonymously which users are visiting the site, or set by content embedded into some pages, such as YouTube or other media service providers.</p>

        <h2 className="font-semibold text-2xl my-4 text-skured-500">Removing/disabling cookies</h2>
        <p>Managing your cookies and cookie preferences must be done from within your browser's options/preferences. Here is a list of guides on how to do this for popular browser software:</p>
        <p>Note: removing/disabling cookies may affect the functionality of the site and services. skUnity can only run a fully fuctional service with no cookies being removed/blocked.</p>
        <p>Find out how to manage cookies on popular browsers:</p>
        <ul className="list-inside list-disc">
          <li><a target="_blank" className="sku-link" href="https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">Microsoft Internet Explorer</a></li>
          <li><a target="_blank" className="sku-link" href="https://support.microsoft.com/en-gb/help/4468242/microsoft-edge-browsing-data-and-privacy">Microsoft Edge</a></li>
          <li><a target="_blank" className="sku-link" href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer">Mozilla Firefox (Desktop only)</a></li>
          <li><a target="_blank" className="sku-link" href="https://support.google.com/chrome/answer/95647?hl=en">Google Chrome (Computer, Android, iPhone & iPad)</a></li>
          <li><a target="_blank" className="sku-link" href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac">Safari for macOS</a></li>
          <li><a target="_blank" className="sku-link" href="https://support.apple.com/en-gb/HT201265">Safari for iOS</a></li>
        </ul>

        <p>To find information relating to other browsers, visit the browser developer's website.</p>

        <h3 className="font-semibold text-xl my-4 text-skured-500">More information about cookies</h3>
        <p>To learn more about cookies, and find more information about blocking certain types of cookies, please visit the <a target="_blank" className="sku-link" href="https://ico.org.uk/your-data-matters/online/cookies/">ICO website Cookies page</a>.</p>
      </div>
      
    </div>
  )
}
export default injectIntl(Cookies)