import React from 'react'
import Helmet from 'react-helmet'
import '../static/duck.scss'

const Error404 = (props) => {
    console.log(props)
    return (
      <div id="404" className="flex-1 container flex flex-wrap items-center justify-center">
        <Helmet>
          <title>{props.title}</title>
          <meta name="description" content="skUnity 404 Error" />
        </Helmet>
      	<span className="font-neris text-3xl text-skugray-700 border-r-2 border-skuray-700 px-4">404</span>
      	<span className="font-neris text-2xl text-skugray-700 px-2">Not found</span>
      	<div className="box-canvas">
    		  <div className="pond"></div>
    		  <div className="tail"></div>
    		  <div className="body"></div>
    		  <div className="head"></div>
    		  <div className="bill"></div>
    		  <div className="water"></div>
    		</div>
      </div>
    )
}

export default Error404;
