import React, { useContext } from 'react'
import Helmet from 'react-helmet'
import { injectIntl } from 'react-intl'
import { AuthContext } from '../../context/auth'

const Admin = (props) => {
  const context = useContext(AuthContext)
  const translate = (id) => {
    return props.intl.messages[id]
  }
  
  return (
    <div id="Admin" className="flex-1 overflow-auto">
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content="skUnity Admin" />
      </Helmet>
      <div className="bg-skunity-dark">
        <div className="container grid grid-cols-1 sm:grid-cols-2 py-32 px-5">
          <div>
            <p className="md:text-6xl font-bold text-white text-5xl w-5/6 sm:w-full lg:w-5/6 ">Admin panel</p>
          </div>
        </div>
      </div>
    </div>
  )
}
export default injectIntl(Admin)