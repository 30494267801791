import { monaco } from '@monaco-editor/react'

const defineSkript = monaco.init()
  .then((monacoInstance) => {
    monacoInstance.languages.register({ id: 'Skript' })
    const config = {
      autoIndent: true,
      autoClosingOvertype: 'auto',
      surroundingPairs: [
        { open: '{', close: '}' },
        { open: '[', close: ']' },
        { open: '(', close: ')' },
        { open: '<', close: '>' },
        { open: "'", close: "'" },
        { open: '"', close: '"' },
        { open: '%', close: '%' }
      ],
      autoClosingPairs: [
        { open: '{', close: '}' },
        { open: '%', close: '%' },
        { open: '[', close: ']' },
        { open: '(', close: ')' },
        { open: "'", close: "'", notIn: ['string', 'comment'] },
        { open: '"', close: '"', notIn: ['string', 'comment'] }
      ],
      onEnterRules: [{
        beforeText: /:$/,
        action: {
          indentAction: monacoInstance.languages.IndentAction.Indent,
        }
      },
      {
        beforeText: /stop$/,
        action: {
          indentAction: monacoInstance.languages.IndentAction.Outdent,
        }
      }]
    }
    monacoInstance.languages.setLanguageConfiguration('Skript', config)
    monacoInstance.languages.setMonarchTokensProvider('Skript', {
      tokenizer: {
        root: [
          [/command .*?:/, "command"],
          [/function .*?:/, "function"],
          [/{_.*?}/, "local-variable"],
          [/{.{1,}}/, "variable"],
          [/#.*/, "comment"],
          [/(".*?)(%{.{1,}}%)(.*?")/, ["string","variable","string"]],
          [/(".*?)(%(?!%).{1,}%)(.*?")/, ["string","text-variable","string"]],
          [/(".*?)(?!%.*?%)(.*?")/, ["string","string"]],
          [/(stop|cancel event|exit)/, "stop"],
          [/^(\t| )*(if|else if|else|loop)/, "condition"],
        ]
      }
    })
    monacoInstance.editor.defineTheme('skUnity', {
      base: 'vs-dark',
      inherit: true,
      rules: [
        { foreground: 'ffffff' },
        { token: 'comment', foreground: '8DBB83' },
        { token: 'command', foreground: 'D98F53', fontStyle: 'bold' },
        { token: 'function', foreground: 'D98F53', fontStyle: 'bold' },
        { token: 'variable', foreground: '46B4BF' },
        { token: 'text-variable', foreground: '46B4BF' },
        { token: 'local-variable', foreground: '46AABF' },
        { token: 'string', foreground: '8A8A8A' },
        { token: 'stop', foreground: 'F44336' },
        { token: 'condition', foreground: 'D6DA34' },
      ]
    });
    monacoInstance.languages.registerCompletionItemProvider('Skript', {
      provideCompletionItems: () => {
        var suggestions = [{
          label: 'cancel event',
          kind: monacoInstance.languages.CompletionItemKind.Text,
          insertText: 'cancel event'
        }, {
          label: 'message',
          kind: monacoInstance.languages.CompletionItemKind.Keyword,
          insertText: 'message "${1:content}"',
          insertTextRules: monacoInstance.languages.CompletionItemInsertTextRule.InsertAsSnippet
        }, {
          label: 'send',
          kind: monacoInstance.languages.CompletionItemKind.Keyword,
          insertText: 'send "${1:content}" to ${0:player}',
          insertTextRules: monacoInstance.languages.CompletionItemInsertTextRule.InsertAsSnippet
        },
        {
          label: 'command',
          kind: monacoInstance.languages.CompletionItemKind.Snippet,
          insertText: [
            'command /${1:name} <${2:argument}>:',
            '\tdescription: ',
            '\tusage: # If omitted this defaults to something similar to how the command was defined above',
            '\tpermission: # If omitted this command can be used by everyone regardless of permissions.',
            '\tpermission message: # If omitted this defaults to "You don\'t have the required permission to use this command"',
            '\texecutable by: # If omitted this command can be executed by players and the console.',
            '\taliases: # a list of aliases of the command which are usually abbreviations of the command',
            '\ttrigger:',
            '\t\t$0'].join('\n'),
          insertTextRules: monacoInstance.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'This is a description about this autocompletion'
        }, {
          label: 'ifelse',
          kind: monacoInstance.languages.CompletionItemKind.Snippet,
          insertText: [
            'if ${1:condition}:',
            '\t$2',
            'else:',
            '\t$0'
          ].join('\n'),
          insertTextRules: monacoInstance.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'If-Else Statement'
        }];
        return { suggestions: suggestions };
      }
    })
})

export default defineSkript