import enMessages from './en/messages.json'
import esMessages from './es/messages.json'

const Localization = {
  'en': enMessages,
  'en-US': enMessages,
  'es': esMessages,
  'es-ES': esMessages
}

export default Localization