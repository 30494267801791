import Home from './Home'
import Addons from './Addons'
import SingleAddon from './SingleAddon'


const Manage = {
	Home,
	Addons,
	SingleAddon
}

export default Manage