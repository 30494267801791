import React from 'react'

class LazyLoad extends React.Component {
	constructor(props) {
		super(props)
		this.div = React.createRef()
		this.state = {
			offset: props.offset ? props.offset : 1000,
			preLoad: props.preLoad ? props.preLoad : 20,
			divHeight: this.div.current ? this.div.current.getBoundingClientRect().height : 0
		}
		this.id = this.props.id ? this.props.id : "lazyload"

		this.onScroll = this.onScroll.bind(this)
	}

	componentDidMount () {
    document.getElementById(this.id).addEventListener('scroll', this.onScroll)
  }

  componentDidUpdate() {
  	let preLoad = this.props.preLoad ? this.props.preLoad : 20
  	if(this.state.preLoad > preLoad && this.state.divHeight - this.div.current.getBoundingClientRect().height > 2000){
  		this.setState({...this.state, preLoad: this.props.preLoad ? this.props.preLoad : 20})
  	}
  }

  onScroll(e){
  	if(!this.div.current) return
  	if(this.div.current.lastChild.getBoundingClientRect().bottom - this.div.current.getBoundingClientRect().height < this.state.offset){
  		this.setState({...this.state, preLoad: this.state.preLoad + 1, divHeight: this.div.current.getBoundingClientRect().height})
  	}
  }

  render() {
	  return (
	    <div className={this.props.className} id={this.id} ref={this.div} style={this.props.style} htmlFor={this.props.htmlFor}>
	    	{this.props.children.slice(0, this.state.preLoad)}
	    </div>
	  )
	}
}

export default LazyLoad