import React, { useState } from 'react'
import Helmet from 'react-helmet'
import { injectIntl } from 'react-intl'
import superagent from 'superagent'
import Swal from 'sweetalert2'
import marked from 'marked'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faExclamationTriangle, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'

const Skript = (props) => {
  const [releases, setReleases] = useState()
  const translate = (id) => {
    return props.intl.messages[id]
  }
  if(!releases) superagent.get('https://api.github.com/repos/SkriptLang/Skript/releases')
    .then(res => { setReleases(res.body) })
  
  const skriptReleaseSwal = (release) => {
    var body = marked(release.body)
    if(release.prerelease) {
      body = '<div class="container">' +
                '<div class="m-2">' +
                  '<div class="rounded bg-skugray-900 p-4 mb-4">' +
                    '<span class="font-bold text-skured-500">PRE-RELEASE: </span>' +
                    '<span class="text-white">You should not be using this version of Skript in production.</span>' +
                  '</div>' +
                '</div>' +
                body +
              '</div>'
              
    }
    body = '<div class="container">' +
            body +
            '<a class="mt-4 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center" href="' + release.assets[0].browser_download_url + '" download>' +
            '<svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>' +
            '<span>Download</span>' +
          '</a>' +
          '</div>'
    Swal.fire({
      title: release.name,
      width: 'auto',
      html: body,
      showCloseButton: true,
      showConfirmButton: false,
      focusClose: false,
    })
  }

  if(!releases){
    return (
      <div id="Skript" className="flex-1 overflow-auto">
        <Helmet>
          <title>{props.title}</title>
          <meta name="description" content="skUnity Skript" />
        </Helmet>
        <div className="container my-16 px-5 grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <p className="md:text-5xl font-bold text-skugray-900 text-4xl w-full">About Skript</p>
            <p className="md:text-lg text-skugray-900 text-md w-full"><FontAwesomeIcon className="text-1xl text-skured-500" icon={faArrowCircleRight} /> Skript is a server plugin created by Njol in 2011. Njol left the development of Skript and a team has formed to continue it called SkriptLang.</p>
            <br />
            <p className="md:text-lg text-skugray-900 text-md w-full"><FontAwesomeIcon className="text-1xl text-skured-500" icon={faArrowCircleRight} /> skUnity has provided a home for Skript community since 2014 and is the central hub for everything Skript with unique tools and the largest community</p>
            <br />
            <p className="md:text-lg text-skugray-900 text-md w-full"><FontAwesomeIcon className="text-1xl text-skured-500" icon={faArrowCircleRight} /> Stuck with Skript? Ask on the <a href="https://forums.skunity.com" className="text-skured-500">skUnity Forums</a> or <a href="https://discord.gg/0l3WlzBPKX7WNjkf" className="text-skured-500">skUnity Discord</a>. Bug with Skript? Post on the <a href="https://github.com/skriptlang/skript/issues" className="text-skured-500">SkriptLang Repo</a></p>
          </div>
          <div>
            <p className="md:text-5xl font-bold text-skugray-900 text-4xl w-full">Download Skript</p>
            <p className="md:text-lg text-skugray-900 text-md w-full">Directly from the SkriptLang GitHub Repository. Click a release below to view the update log and download that release.</p>
            
            <br />
            <p className="md:text-lg text-skugray-900 text-md w-full"><FontAwesomeIcon className="text-1xl text-skured-500" icon={faArrowCircleRight} /> Latest release of Skript: loading...</p>
            <p className="md:text-lg text-skugray-900 text-md w-full"><FontAwesomeIcon className="text-1xl text-skured-500" icon={faExclamationTriangle} /> is for pre-release build</p>
            <p className="md:text-lg text-skugray-900 text-md w-full"><FontAwesomeIcon className="text-1xl text-skured-500" icon={faStar} /> is for full releases and <strong>recommended</strong> versions</p>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div id="Skript" className="flex-1 overflow-auto">
        <div className="container my-16 px-5 grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <p className="md:text-5xl font-bold text-skugray-900 text-4xl w-full">About Skript</p>
            <p className="md:text-lg text-skugray-900 text-md w-full"><FontAwesomeIcon className="text-1xl text-skured-500" icon={faArrowCircleRight} /> Skript is a server plugin created by Njol in 2011. Njol left the development of Skript and a team has formed to continue it called SkriptLang.</p>
            <br />
            <p className="md:text-lg text-skugray-900 text-md w-full"><FontAwesomeIcon className="text-1xl text-skured-500" icon={faArrowCircleRight} /> skUnity has provided a home for Skript community since 2014 and is the central hub for everything Skript with unique tools and the largest community</p>
            <br />
            <p className="md:text-lg text-skugray-900 text-md w-full"><FontAwesomeIcon className="text-1xl text-skured-500" icon={faArrowCircleRight} /> Stuck with Skript? Ask on the <a href="https://forums.skunity.com" className="text-skured-500">skUnity Forums</a> or <a href="https://discord.gg/0l3WlzBPKX7WNjkf" className="text-skured-500">skUnity Discord</a>. Bug with Skript? Post on the <a href="https://github.com/skriptlang/skript/issues" className="text-skured-500">SkriptLang Repo</a></p>
          </div>
          <div>
            <p className="md:text-5xl font-bold text-skugray-900 text-4xl w-full">Download Skript</p>
            <p className="md:text-lg text-skugray-900 text-md w-full">Directly from the SkriptLang GitHub Repository. Click a release below to view the update log and download that release.</p>
            
            <br />
            <p className="md:text-lg text-skugray-900 text-md w-full"><FontAwesomeIcon className="text-1xl text-skured-500" icon={faArrowCircleRight} /> Latest release of Skript: {releases[0].tag_name}</p>
            <p className="md:text-lg text-skugray-900 text-md w-full"><FontAwesomeIcon className="text-1xl text-skured-500" icon={faExclamationTriangle} /> is for pre-release build</p>
            <p className="md:text-lg text-skugray-900 text-md w-full"><FontAwesomeIcon className="text-1xl text-skured-500" icon={faStar} /> is for full releases and <strong>recommended</strong> versions</p>
          </div>
        </div>
        <div className="container mt-32 px-5">
          <p className="md:text-4xl font-bold text-skugray-900 text-3xl w-full">Releases</p>
        </div>
        <div className="container px-5 flex grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {releases.map((release) => (
            <div className="m-2" key={release.tag_name}>
              <div className="group transition duration-100 bg-skugray-100 h-full p-12 hover:bg-skured-500 transform hover:scale-102 cursor-pointer text-skugray-900 hover:text-white" onClick={(e) => skriptReleaseSwal(release)}>
                <p className="mb-2 font-bold text-xl">
                  {release.prerelease ? <FontAwesomeIcon className="text-1xl text-skured-500 group-hover:text-white" icon={faExclamationTriangle} /> : <FontAwesomeIcon className="text-1xl text-skured-500 group-hover:text-white" icon={faStar} />} {release.tag_name}
                </p>
                <p>{release.name}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
  
}
export default injectIntl(Skript)