import React, { useContext } from 'react'
import Helmet from 'react-helmet'
import { injectIntl } from 'react-intl'
import {  useQuery, useMutation } from "@apollo/react-hooks"
import { Link } from 'react-router-dom'
import _ from 'lodash'

import { AuthContext } from '../../context/auth'
import ManageSideBar from '../../components/Manage/SideNav'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight, faSpinner } from '@fortawesome/free-solid-svg-icons'

import { MY_ADDONS } from './gql'

const MyAddons = (props) => {
  const { user } = useContext(AuthContext)
  const translate = (id) => {
    return props.intl.messages[id]
  }

  const { data: myAddonsData, loading: myAddonsLoading, error: myAddonsError} = useQuery(MY_ADDONS)

  let myAddons
  let myDocs = {
    events: [],
    conditions: [],
    effects: [],
    expressions: [],
    types: []
  }

  if(myAddonsData){
    let dataSorted = _.sortBy(myAddonsData.getMyAddons, [addon => addon.name.toLowerCase()])
    let addonsOwned = _.partition(dataSorted, (addon) => addon.author === user.user_id)
    //let addonsCollab = _.filter(data, {author: user.username})
    //let addonsLeft = _.reject(dataSorted, {author: user.username})
    myAddons = _.concat(addonsOwned[0],addonsOwned[1])
  }

  return (
    <div id="MyAddons" className="flex-1 overflow-auto">
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content="skUnity Me" />
      </Helmet>
      <div className="flex h-full">
        <ManageSideBar/>
        <div aria-label="breadcrumb" className="flex-1 overflow-y-auto">
          <nav aria-label="breadcrumb" className="fixed w-full px-4 py-2 bg-skugray-100 shadow-sm">
            <Link to="/manage" className="sku-link">Dashboard</Link>
            <span className="font-semibold px-2">/</span>
            My Addons
          </nav>
          <div className="py-12 px-6 md:px-12 md:py-16">
            <h1 className="font-semibold text-skugray-900 text-2xl">{translate('me.myAddons')}</h1>
            <div className="grid grid-cols-2 lg:grid-cols-5 gap-4 mt-4">
              
              <div className="col-span-2 md:col-span-1 bg-skugray-100 py-2 px-4">
                <div className="flex text-center">
                  <h2 className="flex-1 font-semibold text-lg">Events</h2>
                </div>
                <div className="py-2 text-skured-500 font-semibold text-center">{myDocs.events.length}</div>
              </div>

              <div className="bg-skugray-100 py-2 px-4">
                <div className="flex text-center">
                  <h2 className="flex-1 font-semibold text-lg">Conditions</h2>
                </div>
                <div className="py-2 text-skured-500 font-semibold text-center">{myDocs.conditions.length}</div>
              </div>

              <div className="bg-skugray-100 py-2 px-4">
                <div className="flex text-center">
                  <h2 className="flex-1 font-semibold text-lg">Effects</h2>
                </div>
                <div className="py-2 text-skured-500 font-semibold text-center">{myDocs.effects.length}</div>
              </div>

              <div className="bg-skugray-100 py-2 px-4">
                <div className="flex text-center">
                  <h2 className="flex-1 font-semibold text-lg">Expressions</h2>
                </div>
                <div className="py-2 text-skured-500 font-semibold text-center">{myDocs.expressions.length}</div>
              </div>

              <div className="bg-skugray-100 py-2 px-4">
                <div className="flex text-center">
                  <h2 className="flex-1 font-semibold text-lg">Types</h2>
                </div>
                <div className="py-2 text-skured-500 font-semibold text-center">{myDocs.types.length}</div>
              </div>

            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-4">
              <div className="bg-skugray-100 py-2 px-4">
                <div className="flex items-center">
                  <h2 className="flex-1 font-semibold text-lg">Addons</h2>
                  <Link to="/manage/addons" className="flex-none font-semibold sku-link">{translate('me.seeAll')} <FontAwesomeIcon icon={faLongArrowAltRight} /></Link>
                </div>
                <div className="py-4">
                  {myAddonsLoading && <div className="w-full text-center"><FontAwesomeIcon className="animate-spin text-3xl text-skugray-500" icon={faSpinner} /></div>}
                  {myAddonsData && myAddons.map((addon) => <Link to={"/manage/addons/"+addon.name} key={addon.id} className="block w-max sku-link font-semibold">{addon.name}</Link>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default injectIntl(MyAddons)