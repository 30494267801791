import React, { useContext, useState } from 'react'
import Helmet from 'react-helmet'
import { useMutation } from "@apollo/react-hooks"
import { injectIntl } from 'react-intl'
import gql from "graphql-tag"
import { AuthContext } from '../context/auth'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const LOGIN_USER = gql `
  mutation login($email: String!, $password: String!, $code: String) {
    login(email: $email, password: $password, code: $code){
      token
    }
  }
`;

const Login = (props) => {

  const translate = (id) => {
    return props.intl.messages[id]
  }

  const context = useContext(AuthContext)

  const [loginError, setLoginError] = useState({})
  const [state, setState] = useState({
    loginEmail: '',
    loginPassword: '',
    loginCode: ''
  })
  const [twoFA, set2FA] = useState(false)

  const [login, {loading: loginLoading}] = useMutation(LOGIN_USER, {
    update(_, { data: { login: userData } }) {
      context.login(userData)
      props.history.push(props.location.state.from)
    },
    onError(err){
      if(err.graphQLErrors[0].message === "Requires 2FA"){
        set2FA(true)
        document.getElementsByName('loginCode1')[0].focus()
      }
      setLoginError(err.graphQLErrors[0].extensions.exception.errors)
    },
    variables: {
      email: state.loginEmail,
      password: state.loginPassword,
      code: state.loginCode
    }
  })

  const handleInputChange = (e) => {
    if(e.target.name.includes("loginCode")){
      let nextInput = e.target.nextElementSibling ? e.target.nextElementSibling : e.target
      if(e.target.value !== '') nextInput.focus()
      let code = ''
      for (var i = 1; i < 7; i++) {
        code += document.getElementsByName('loginCode' + i)[0].value
      }
      setState({...state, loginCode: code})
    } else {
      setState({...state, [e.target.name]: e.target.value})
    }
  }

  const handleInputKeyPress = (e) => {
    if(e.key !== "Control" && e.key !== "v" && e.key !== "Enter"){
      e.preventDefault()
    }
    let prevInput = e.target.previousElementSibling ? e.target.previousElementSibling : e.target
    if(e.key === "Backspace"){
      if(e.target.value === ''){
        prevInput.value = ''
        prevInput.focus()
      }
      if(e.target.value !== '') e.target.value = ''
      handleInputChange(e)
    }
    if(isFinite(e.key)){
      e.target.value = e.key
      handleInputChange(e)
    }
  }

  const handlePaste = (e) => {
    e.preventDefault()
    let clipboard = e.clipboardData.getData('Text').substring(0, Math.min(e.clipboardData.getData('Text').length,6))
    for (var i = 0; i < clipboard.length; i++) {
      let n = i+1
      document.getElementsByName('loginCode' + n)[0].value = clipboard.charAt(i)
      e.target = document.getElementsByName('loginCode' + n)[0]
      e.target.focus()
    }
    handleInputChange(e)
  }

  const handleLogin = (e) => {
    e.preventDefault()
    login()
    console.log(state.loginCode)
  }

  let form = (
    <form className="" action="#" onSubmit={handleLogin}>
      <div className="mb-5">
        <label htmlFor="loginEmail" className="block mb-2 text-sm font-medium text-skugray-600">Username or email</label>
        <input type="text" name="loginEmail" className="block w-full p-3 font-medium rounded bg-white border border-transparent focus:outline-none" value={state.loginEmail} onChange={(e) => { handleInputChange(e) }}/>
      </div>
  
      <div className="mb-5">
        <label htmlFor="loginPassword" className="block mb-2 text-sm font-medium text-skugray-600">Password</label>
        <input type="password" name="loginPassword" className="block w-full p-3 rounded bg-white border border-transparent focus:outline-none" value={state.loginPassword} onChange={(e) => { handleInputChange(e) }}/>
      </div>
      <span className="text-skured-500 py-2 text-sm">{loginError ? loginError.Login : ''}</span>
      <button id="loginSubmit" className="w-full p-3 mt-4 bg-skured-500 text-white rounded shadow">
        {loginLoading ? <FontAwesomeIcon className="animate-spin text-3xl" icon={faSpinner} /> : 'Login'}
      </button>
      <label className="block mb-2 text-sm font-medium text-skugray-600 pt-5">Use your skUnity Forums username and password to login</label>
    </form>)

  if(twoFA){
    form = (
      <form className="" action="#" onSubmit={handleLogin} autoComplete="off">
        <div className="mb-5">
          <label htmlFor="loginCode" className="block mb-2 text-sm font-medium text-skugray-600">2FA Confirmation Code</label>
          <div className="grid grid-cols-6 md:gap-4 gap-2 text-4xl">
            <input type="number" name="loginCode1" className="col-span-1 block font-medium text-center rounded bg-white border border-transparent focus:outline-none" onPaste={handlePaste} onKeyDown={handleInputKeyPress} onChange={handleInputChange}/>
            <input type="number" name="loginCode2" className="col-span-1 block font-medium text-center rounded bg-white border border-transparent focus:outline-none" onPaste={handlePaste} onKeyDown={handleInputKeyPress} onChange={handleInputChange}/>
            <input type="number" name="loginCode3" className="col-span-1 block font-medium text-center rounded bg-white border border-transparent focus:outline-none" onPaste={handlePaste} onKeyDown={handleInputKeyPress} onChange={handleInputChange}/>
            <input type="number" name="loginCode4" className="col-span-1 block font-medium text-center rounded bg-white border border-transparent focus:outline-none" onPaste={handlePaste} onKeyDown={handleInputKeyPress} onChange={handleInputChange}/>
            <input type="number" name="loginCode5" className="col-span-1 block font-medium text-center rounded bg-white border border-transparent focus:outline-none" onPaste={handlePaste} onKeyDown={handleInputKeyPress} onChange={handleInputChange}/>
            <input type="number" name="loginCode6" className="col-span-1 block font-medium text-center rounded bg-white border border-transparent focus:outline-none" onPaste={handlePaste} onKeyDown={handleInputKeyPress} onChange={handleInputChange}/>
          </div>
        </div>

        <span className="text-skured-500 py-2 text-sm">{loginError ? loginError.Credentials : ''}</span>
        <button id="loginSubmit" className="w-full p-3 mt-4 bg-skured-500 text-white rounded shadow">
          {loginLoading ? <FontAwesomeIcon className="animate-spin text-3xl" icon={faSpinner} /> : 'Confirm'}
        </button>
        <br />
        <label className="block mb-2 text-sm font-medium text-skugray-600 pt-5">skUnity Docs only supports 2FA via an authenticator app. Email 2FA is not supported.</label>
      </form>)
  }
  
  return (
    <div id="Login" className="flex-1 bg-white">
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content="skUnity Login" />
      </Helmet>
      <div className="container p-8 flex min-h-full items-center pt-24">
        <div className="max-w-md w-full mx-auto">
            <div className="bg-skugray-100 rounded-lg overflow-hidden">
                <div className="p-8">
                    {form}
                </div>
                <div className="flex justify-between p-8 text-sm border-t bg-skugray-900">
                    <a href="https://forums.skunity.com/register/" className="font-medium text-indigo-500">Create account</a>
                    <a href="https://forums.skunity.com/lost-password/" className="text-skugray-600">Forgot password?</a>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}
export default injectIntl(Login)