import React from 'react'
import { BrowserRouter as Router, Switch, Link } from 'react-router-dom'

import { AuthProvider } from './context/auth'
import { DocsProvider } from './context/docs'
import { AuthRoute, PrivateRoute, AdminRoute, PublicRoute } from './util/Routes'

import CookieConsent from 'react-cookie-consent'
import Navigation from './components/Navigation'
import Pages from './pages/index'

const App = (props) => {
  return (
    <AuthProvider>
      <DocsProvider>
        <Router>
          <Navigation />
          <Switch>
            <PublicRoute exact path="/" component={ Pages.Home } title="skUnity | Home" />
            <PublicRoute exact path="/docs" component={ Pages.Docs } title="skUnity | Documentation" />
            <PublicRoute exact path="/docs/:filter" component={ Pages.Docs } title="skUnity | Documentation" />
            <PublicRoute exact path="/docs/id/:id" component={ Pages.Docs } title="skUnity | Documentation" />
            <PublicRoute exact path="/docs/:tag/:filter" component={ Pages.Docs } title="skUnity | Documentation" />
            <PublicRoute exact path="/docs/:tag/:tag2/:filter" component={ Pages.Docs } title="skUnity | Documentation" />
            <PublicRoute exact path="/parser" component={ Pages.Parser } title="skUnity | Parser" />
            <PublicRoute exact path="/skript" component={ Pages.Skript } title="skUnity | Get Skript" />
            <PublicRoute exact path="/donate" component={ Pages.Donate } title="skUnity | Donate" />
            <AuthRoute exact path="/login" component={ Pages.Login } title="skUnity | Login" />

            <PrivateRoute exact path="/manage" component={ Pages.Manage.Home } title="skUnity | Dashboard" />
            <PrivateRoute exact path="/manage/addons" component={ Pages.Manage.Addons } title="skUnity | My Addons" />
            <PrivateRoute exact path="/manage/addons/:addon" component={ Pages.Manage.SingleAddon } title="skUnity | My Addons" />
            
            <AdminRoute exact path="/admin" component={ Pages.Admin.Home } title="skUnity | Admin" />
            <PublicRoute exact path="/cookies" component={ Pages.Legal.Cookies } title="skUnity | Cookies" />
            <PublicRoute exact path="/privacy" component={ Pages.Legal.Privacy } title="skUnity | Privacy Policy" />
            <PublicRoute component={Pages.Error404} title="skUnity | 404" />
          </Switch>
          <div className="fixed bottom-0 w-full bg-skugray-800">
          <CookieConsent
            cookieName="cookieconsent_status"
            cookieValue="dismiss"
            disableStyles="true"
            containerClasses="px-8 flex text-white p-4 items-center"
            contentClasses="flex-1"
            buttonWrapperClasses="flex-none"
            buttonClasses="sku-btn border-skured-500 after after:bg-skured-500"
            buttonStyle={{color:'white'}}
          >skUnity uses cookies to enhance the user experience. <Link className="sku-link" to="/cookies">Learn More</Link></CookieConsent>
        </div>
        </Router>
      </DocsProvider>
    </AuthProvider>
  )
}

export default App