import React, { useContext } from 'react'
import Helmet from 'react-helmet'
import { injectIntl } from 'react-intl'
import {  useQuery, useMutation } from "@apollo/react-hooks"
import { Link } from 'react-router-dom'
import _ from 'lodash'

import { AuthContext } from '../../context/auth'
import ManageSideBar from '../../components/Manage/SideNav'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight, faSpinner } from '@fortawesome/free-solid-svg-icons'

import { GET_ADDON, GET_USER } from './gql'

const SingleAddon = (props) => {
  const { user } = useContext(AuthContext)
  const translate = (id) => {
    return props.intl.messages[id]
  }

  const getUser = (userId) => {

  }

  const { data: myAddonData, loading: myAddonLoading, error: myAddonError} = useQuery(GET_ADDON, {
    variables: {
      name: props.match.params.addon
    }
  })

  const loadingIcon = <FontAwesomeIcon className="animate-spin text-xl text-skured-500" icon={faSpinner} />

  let myAddon
  let myDocs = {
    events: [],
    conditions: [],
    effects: [],
    expressions: [],
    types: []
  }

  if(myAddonData && props.match.params.addon){
    myAddon = myAddonData.getAddonByName
    myDocs.events = _.filter(myAddon.docs, {type: "event"})
    myDocs.conditions = _.filter(myAddon.docs, {type: "condition"})
    myDocs.effects = _.filter(myAddon.docs, {type: "effect"})
    myDocs.expressions = _.filter(myAddon.docs, {type: "expression"})
    myDocs.types = _.filter(myAddon.docs, {type: "type"})
  }

  if(myAddonError){
    props.history.push('/manage/addons')
  }

  return (
    <div id="MyAddons" className="flex-1">
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content="skUnity Me" />
      </Helmet>
      <div className="flex h-full">
        <ManageSideBar/>
        <div className="flex-1 overflow-y-auto">
          <nav aria-label="breadcrumb" className="fixed w-full px-4 py-2 bg-skugray-100 shadow-sm">
            <Link to="/manage" className="sku-link">Dashboard</Link>
            <span className="font-semibold px-2">/</span>
            <Link to="/manage/addons" className="sku-link">My Addons</Link>
            <span className="font-semibold px-2">/</span>
            {props.match.params.addon}
          </nav>
          <div className="py-12 px-6 md:px-12 md:py-16">
            <h1 className="font-semibold text-skugray-900 text-2xl">{props.match.params.addon}</h1>
            <div className="grid grid-cols-2 lg:grid-cols-5 gap-4 mt-4">
              
              <div className="col-span-2 md:col-span-1 bg-skugray-100 py-2 px-4">
                <div className="flex text-center">
                  <h2 className="flex-1 font-semibold text-lg">Events</h2>
                </div>
                <div className="py-2 text-skured-500 font-semibold text-center">{myAddonLoading ? loadingIcon : myDocs.events.length}</div>
              </div>

              <div className="bg-skugray-100 py-2 px-4">
                <div className="flex text-center">
                  <h2 className="flex-1 font-semibold text-lg">Conditions</h2>
                </div>
                <div className="py-2 text-skured-500 font-semibold text-center">{myAddonLoading ? loadingIcon : myDocs.conditions.length}</div>
              </div>

              <div className="bg-skugray-100 py-2 px-4">
                <div className="flex text-center">
                  <h2 className="flex-1 font-semibold text-lg">Effects</h2>
                </div>
                <div className="py-2 text-skured-500 font-semibold text-center">{myAddonLoading ? loadingIcon : myDocs.effects.length}</div>
              </div>

              <div className="bg-skugray-100 py-2 px-4">
                <div className="flex text-center">
                  <h2 className="flex-1 font-semibold text-lg">Expressions</h2>
                </div>
                <div className="py-2 text-skured-500 font-semibold text-center">{myAddonLoading ? loadingIcon : myDocs.expressions.length}</div>
              </div>

              <div className="bg-skugray-100 py-2 px-4">
                <div className="flex text-center">
                  <h2 className="flex-1 font-semibold text-lg">Types</h2>
                </div>
                <div className="py-2 text-skured-500 font-semibold text-center">{myAddonLoading ? loadingIcon : myDocs.types.length}</div>
              </div>

            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-4">
              <div className="bg-skugray-100 py-2 px-4">
                <h2 className="flex-1 font-semibold text-lg">Contributors</h2>
                <div className="py-4">
                  {myAddonData && myAddon.author}
                  {myAddonData &&  myAddon.collaborators.map(
                    contributor => 
                      <span>{contributor}</span>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default injectIntl(SingleAddon)