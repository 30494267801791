import React from 'react'
import App from './App'
import ApolloClient from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { ApolloProvider } from '@apollo/react-hooks'
import { setContext } from '@apollo/client/link/context'

import { getMainDefinition } from 'apollo-utilities'

import { IntlProvider } from 'react-intl'
import Localization from './localization/localization'

const host = '85.137.243.251'

const httpLink = new HttpLink({
    uri: 'http://' + host + ':4000/graphql',
  })

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('jwtToken');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})

const language = navigator.language.split(/[-_]/)[0]
const messages = Localization[language] ? Localization[language] : Localization['en']

export default (
  <IntlProvider locale={language} messages={messages}>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </IntlProvider>
)