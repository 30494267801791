import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import Helmet from 'react-helmet'
import { injectIntl } from 'react-intl'
import { AuthContext } from '../../context/auth'

const Privacy = (props) => {
  const context = useContext(AuthContext)
  const translate = (id) => {
    return props.intl.messages[id]
  }
  return (
    <div id="Privacy" className="flex-1 overflow-auto">
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content="skUnity Me" />
      </Helmet>
      <div className="bg-skugray-900">
        <div className="container pt-16 pb-12 px-5">
          <h1 className="md:text-5xl font-bold text-white text-4xl">Privacy Policy</h1>
          <p className="text-skugray-400 pt-2 pl-4">Last updated: 7th of December 2020</p>
        </div>
      </div>

      <div className="container px-12 mt-12 space-y-4 max-w-6xl mb-32">
        <p>We are skUnity Hosting ("we", "our", "us"). We’re committed to protecting and respecting your privacy. If you have questions about your information, please contact us.</p>

        <h2 className="font-semibold text-2xl my-4 text-skured-500">What information we hold about you</h2>
        <p>The type of data that we collect and process includes:</p>
        <ul className="list-inside list-disc">
          <li>Your name or username.</li>
          <li>Your email address.</li>
          <li>Your IP address.</li>
          <li>Further data may be collected as you choose to share it. For example, filling out the fields on your profile.</li>
        </ul>

        <p>We collect some or all of this information in the following cases:</p>
        <ul className="list-inside list-disc">
          <li>You register as a member on this site.</li>
          <li>You fill out our contact form.</li>
          <li>You browse this site. See the "Cookie policy" below.</li>
          <li>You fill out the fields on your profile.</li>
          <li>How your information is used</li>
        </ul>

        <h2 className="font-semibold text-2xl my-4 text-skured-500">How your information is used</h2>
        <p>We may use your information in the following ways:</p>
        <ul className="list-inside list-disc">
          <li>To make you a registered member of our site, in order for you to purchase content from this site and access your services.</li>
          <li>We may use your email address to inform you of the activity on our site and service information such as service maintenance and product updates.</li>
          <li>Your IP address is recorded when you perform certain actions on our site. Your IP address is never public.</li>
        </ul>

        <h2 className="font-semibold text-2xl my-4 text-skured-500">Other ways we may use your information.</h2>
        <p>Notifying you of activity on our site which may be relevant to you, from time to time we may wish to communicate with all members any important information such as newsletters or announcements by email. You can opt-in to or opt-out of such emails in your profile.</p>

        <p>We may collect non-personally identifiable information about you during your interaction with our site. This information may include technical information about the browser or type of device you are using. This information will be used purely for the purposes of analytics and tracking the number of visitors to our site.</p>

        <h2 className="font-semibold text-2xl my-4 text-skured-500">Keeping your data secure</h2>
        <p>We are committed to ensuring that any information you provide to us is secure. In order to prevent unauthorized access or disclosure, we have put in place suitable measures and procedures to safeguard and secure the information that we collect.</p>
        
        <h2 className="font-semibold text-2xl my-4 text-skured-500">Cookie policy</h2>
        <p>Cookies are small text files which are set by us on your computer which allows us to provide certain functionality on our site, such as being able to log in, or remembering certain preferences.</p>
        <p>We have a detailed cookie policy and more information about the cookies that we set on <Link to="/cookies" className="sku-link">this page</Link>.</p>

        <h2 className="font-semibold text-2xl my-4 text-skured-500">Rights</h2>
        <p>You have a right to access the personal data we hold about you or obtain a copy of it. To do so please contact us. If you believe that the information we hold for you is incomplete or inaccurate, you may contact us to ask us to complete or correct that information.</p>

        <p>You also have the right to request the erasure of your data. Please contact us if you would like us to remove your data.</p>

        <h2 className="font-semibold text-2xl my-4 text-skured-500">Acceptance of this policy</h2>
        <p>Continued use of our site signifies your acceptance of this policy. If you do not accept the policy, then please do not use this site. When registering we will further request your explicit acceptance of the privacy policy.</p>

        <h2 className="font-semibold text-2xl my-4 text-skured-500">Changes to this policy</h2>
        <p>We may make changes to this policy at any time. You may be asked to review and re-accept the information in this policy if it changes in the future.</p>

      </div>
      
    </div>
  )
}
export default injectIntl(Privacy)