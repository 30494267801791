import React, { useState, useRef } from 'react'
import Helmet from 'react-helmet'
import {injectIntl} from 'react-intl'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import { monaco, ControlledEditor } from '@monaco-editor/react'
import Dropzone from 'react-dropzone'
import '../util/monacoSkript'

import '../static/parser.scss'
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faPlus, faFile, faPlay, faEllipsisV, faSave } from '@fortawesome/free-solid-svg-icons'

const PARSE = gql`
  mutation parseScript($content: String!) {
    parseScript(content: $content) {
      results
      linemap
    }
  }
`;

const Parser = (props) => {

  const editorRef = useRef()
  const editorInstance = useRef()

  const [fileState, setFileState] = useState({status: "Failed", errors: "Welcome to the skUnity Parser! Type some code and your parse results will appear here.", commands: 0, functions: 0, variables: 0})
  const [models, setModels] = useState([])
  var storedModels

  const [parseScript] = useMutation(PARSE, {
    onError: (err) => {
      console.log(err)
    },
    onCompleted: (data) => {
      editorRef.current.getModelMarkers().map((marker) => editorRef.current.setModelMarkers(editorInstance.current.getModel(), marker.owner, [{}]))
      
      let unparsedResult = data.parseScript.results.split("<br>")
      unparsedResult.shift()
      let addons = []
      let parsedData = []
      unparsedResult.map((line, index) => {
        if(line.startsWith("addon")) {
          if(!(line.split(": ")[1] in addons)) {
            addons.push(line.split(": ")[1])
          }
        } else {
          parsedData[index] = updateLine(line, data.parseScript.linemap)
        }
      })
      if(parsedData.length === 0) parsedData[0] = "Successfully parsed with no errors found"
      if(parsedData[0] === "Successfully parsed with no errors found"){
        setFileState({...fileState, status: "Success", errors: parsedData[0]})
      } else {
        setFileState({...fileState, status: "Failed", errors: parsedData})

      }
    }
  })

  if(localStorage.getItem('models')){
    storedModels = JSON.parse(localStorage.getItem('models'))
  } else {
    storedModels = {}
  }

  const parse = () => {
    parseScript({ variables: { content: editorInstance.current.getValue() } })
  }

  const translate = (id) => {
    return props.intl.messages[id]
  }

  const applySmartFix = () => {}

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const getLineFromResult = (result) => {
    const regex = /line (\d*):/gm
    const str = result
    let m

    while ((m = regex.exec(str)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++
      }
      // The result can be accessed through the `m`-variable.
      var returnAble = ""
      m.forEach((match, groupIndex) => {
        returnAble = match
      });
      return returnAble
    }
  }

  const updateLine = (result, linemap) => {
    result = result.replace(/</g, "&lt;").replace(/>/g, "&gt;")

    if(result.startsWith("[skUP:RL]")) return result.replace("[skUP:RL]", "")
    if(result === "<br>Happy New Year from everyone at skUnity!" || result === "Happy New Year from everyone at skUnity!") return result
    if(result === "") return result

    let lineNumber = getLineFromResult(result)
    if(lineNumber === undefined) return result

    result = result.replace(/\[skUP:StartError\](.*?)\[skUP:EndError\]/, <a href="#" onClick={applySmartFix(lineNumber)}>SmartFix</a>)
    result = capitalizeFirstLetter(result)

    editorRef.current.setModelMarkers(editorInstance.current.getModel(), lineNumber, [{
        startLineNumber: parseInt(lineNumber,10),
        startColumn: 1,
        endLineNumber: parseInt(lineNumber,10),
        endColumn: 255,
        message: result,
        severity: "Warning"
    }])

    return { lineNumber: parseInt(lineNumber,10), line: result }
  }

  const handleEditorDidMount = (_, editor) => {
    editorInstance.current = editor
    editor.updateOptions({
      tabSize: 4,
    })
    monaco.init().then((monaco) => {
      editorRef.current = monaco.editor
      editorRef.current.onDidCreateModel((model) => {
        editorInstance.current.setModel(model)
        setModels(editorRef.current.getModels())
        console.log(editorRef.current.getModels())
        storedModels = {...storedModels, [model.uri]: {content: model.getValue(), view: editorInstance.current.saveViewState()}}
        localStorage.setItem('models', JSON.stringify(storedModels))
      })
      //Dispose automatic generated model
      editorRef.current.getModels().map((model) => {
        model.dispose()
      })
      //Restore stored models, if any, from localStorage
      if(Object.keys(storedModels).length > 0){
        const keys = Object.keys(storedModels)
          for (const key of keys) {
            createModel(null, key, storedModels[key].content)
          }
      } else {
        createModel()
      }
    })
  }

  const handleEditorChange = (ev, value) => {
    let [variableTokens, commandTokens, functionTokens] = new Array(3).fill(0)
    let model = editorInstance.current.getModel()
    editorRef.current.tokenize(model.getValue(), "Skript").map((tokens) => {
      tokens.forEach((token) =>{
        if(token.type === "variable.Skript") variableTokens++
        if(token.type === "local-variable.Skript") variableTokens++
        if(token.type === "command.Skript") commandTokens++
        if(token.type === "function.Skript") functionTokens++
      })
    })
    setFileState({...fileState, variables: variableTokens, commands: commandTokens, functions: functionTokens})
    storedModels = {...storedModels, [model.uri]: {content: value, view: editorInstance.current.saveViewState()}}
    localStorage.setItem('models', JSON.stringify(storedModels))
  }

  const modelExists = (uri) => {
    let exists = false
    models.map((model) => {
      if(model.uri === uri) exists = true
    })
    return exists
  }

  //Create a new Tab. (event from button, tabname, content)
  const createModel = (ev = null, name = 'UNTITLED', content = '') => {
    console.log("Creating model " + name)
                                                                                //Sometext( 1 )
    let nameRegex = /(.*?)(\(([0-9]+)\))?(\..*)?$/gi                            //   (1)   (3)
    while(modelExists(name)){
      nameRegex = /(.*?)(\(([0-9]+)\))?(\..*)?$/gi
      let newName = nameRegex.exec(name)
      let num = 1
      if(newName[3] !== undefined) num = parseInt(newName[3]) + 1
      name = newName[1] + '(' + num + ')'
    }
    editorRef.current.createModel(content,'Skript', name)
  }

  //Rename a model (event from doubleclick, model uri [model name])
  const renameModel = (ev, uri = null) => {
    let target = ev.target
    let currentName = target.innerText
    target.setAttribute("contenteditable", "true")
    target.focus()
    target.addEventListener('focusout', (event) => {
      if(modelExists(target.innerText) || target.innerText === ""){
        target.innerText = currentName
        return
      }
      var targetModel
      models.map((model) => {
        if(model.uri === currentName) targetModel = model
      })
      createModel(null, target.innerText, targetModel.getValue())
      closeModel(currentName)
      target.setAttribute("contenteditable", "false")  
    })
    target.onkeypress = (e) => {
      if(e.keyCode === 13){
        e.preventDefault()
        target.setAttribute("contenteditable", "false")
      }
    }
  }

  const closeModel = (uri) => {
    console.log(models)
    console.log('Deleting ' + uri)
    console.log(models)
    models.map((model) => {
      if(model.uri === uri) {
        if(editorInstance.current.getModel().uri === uri) editorInstance.current.setModel(models[0])
        delete storedModels[uri]
        localStorage.setItem('models', JSON.stringify(storedModels))
        model.dispose()
        setModels(editorRef.current.getModels())
      }
    })
  }

  const setModel = (uri) => {
    models.map((model) => {
      if(model.uri === uri) {
        let currentUri = editorInstance.current.getModel().uri
        console.log("Changing from " + currentUri + " to " + uri)
        if(storedModels[currentUri]) storedModels[currentUri].view = editorInstance.current.saveViewState()
        localStorage.setItem('models', JSON.stringify(storedModels))
        editorInstance.current.setModel(model)
        setModels(editorRef.current.getModels())
        if(storedModels[uri]) editorInstance.current.restoreViewState(storedModels[uri].view)
      }
    })
  }

  const handleDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = (e) => {
        let fileRegex = /(.*)\..*$/gi
        let fileName = fileRegex.exec(file.name)
        if(editorInstance.current.getValue().length === 0){
          closeModel(editorInstance.current.getModel().uri)
        }
        createModel(null, fileName[1], e.target.result)
      }
      reader.readAsText(file)
    })
  }

  //onClick on filebar element
  const fileBarHandle = (ev) => {
    let target = ev.target.getAttribute('for')
    if(!target) target = ev.target.parentNode.getAttribute('for')
    if(!document.querySelector("#filebar").classList.contains("active")){
      document.querySelector("#filebar").classList.add("active")
      document.querySelector("#mobileFilebar").classList.add("active")
    }
    document.querySelectorAll('#filebar ul, #mobileFilebar ul').forEach((element) => {
      element.classList.remove('active')
    })
    document.getElementById(target).classList.toggle("active")
  }

  //onMouseOver on filebar element
  const fileBarHandleHover = (ev) => {
    let target = ev.target.getAttribute('for')
    if(document.querySelector("#filebar").classList.contains("active")){
      document.querySelectorAll('#filebar ul').forEach((element) => {
        element.classList.remove('active')
      })
      if(target) document.getElementById(target).classList.toggle("active")
    }
  }

  //What's new? Modal
  const whatsnew = () => {
    Swal.fire({
      title: "What's new?",
      text: "Literally the whole parser",
      showCloseButton: true,
      showConfirmButton: false,
      focusClose: false,
    })
  }

  //Close Filebar if clicked outside of it
  const fileBarOff = (ev) => {
    if(!ev.target.getAttribute('for') && document.querySelector('#editor')){
      document.querySelector('#filebar').classList.remove('active')
      document.querySelector('#mobileFilebar').classList.remove('active')
      document.querySelectorAll('#filebar ul').forEach((element) => {
        element.classList.remove('active')
      })
      document.querySelectorAll('#mobileFilebar ul').forEach((element) => {
        element.classList.remove('active')
      })
    }
  }

  const highlightLine = (ev) => {
    let line = Number.parseInt(ev.target.getAttribute('for'))
    editorInstance.current.revealLinesInCenter(line, line)
  }

  return (
    <div id="parser" className="flex flex-col h-screen bg-skugray-900 overflow-hidden md:overflow-auto" onClick={fileBarOff}>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content="skUnity Parser" />
      </Helmet>
      <div id="mobileFilebar" className="flex flex-row px-8 md:hidden">
        <div className="relative flex-1 text-skugray-400 text-lg">
          <div className="py-2 px-6 text-center"><FontAwesomeIcon icon={faFile}/></div>
        </div>
        <div className="relative flex-1 text-skugray-400 text-lg">
          <div className="py-2 px-6 text-center"><FontAwesomeIcon icon={faSave}/></div>
        </div>
        <div className="relative flex-1 text-skugray-400 text-lg">
          <div className="py-2 px-6 text-center" onClick={parse}><FontAwesomeIcon icon={faPlay}/></div>
        </div>
        <div className="relative flex-1 text-skugray-400 text-lg" htmlFor="mobileMore">
          <div className="py-2 px-6 text-center" onClick={fileBarHandle} htmlFor="mobileMore"><FontAwesomeIcon htmlFor="mobileMore" icon={faEllipsisV}/></div>
          <ul id="mobileMore" className="absolute z-50 bg-black right-0 w-auto text-right">
            <li className="py-2 hover:bg-skugray-800 px-4" onClick={whatsnew}>{translate('parser.filebar.news')}</li>
            <li className="py-2 hover:bg-skugray-800 px-4">{translate('parser.filebar.help.about')}</li>
          </ul>
        </div>
      </div>
      <div id="filebar" className="flex-row px-16 hidden md:flex">
        <div className="relative flex-initial cursor-pointer text-white text-sm hover:bg-black">
          <div className="py-2 px-8" htmlFor="file" onMouseEnter={fileBarHandleHover} onClick={fileBarHandle}>{translate('parser.filebar.file')}</div>
          <ul id="file" className="absolute z-50 bg-black left-0 w-64">
            <li className="py-2 hover:bg-skugray-800 px-4" onClick={createModel}>{translate('parser.filebar.file.new')}</li>
            <Dropzone onDrop={handleDrop}>
              {({getRootProps, getInputProps}) => (
                <li className="py-2 hover:bg-skugray-800 px-4" {...getRootProps()}>
                  <input {...getInputProps()} />
                  {translate('parser.filebar.file.open')}
                </li>
              )}
            </Dropzone>
            <li className="py-2 hover:bg-skugray-800 px-4">{translate('parser.filebar.file.load')}</li>
            <li className="py-2 hover:bg-skugray-800 px-4">{translate('parser.filebar.file.save')}</li>
          </ul>
        </div>
        <div className="relative flex-initial cursor-pointer text-white text-sm hover:bg-black">
          <div className="py-2 px-8" htmlFor="edit" onMouseEnter={fileBarHandleHover} onClick={fileBarHandle}>{translate('parser.filebar.edit')}</div>
          <ul id="edit" className="absolute z-50 bg-black left-0 w-64">
            <li className="py-2 hover:bg-skugray-800 px-4">{translate('parser.filebar.edit.undo')}</li>
            <li className="py-2 hover:bg-skugray-800 px-4">{translate('parser.filebar.edit.redo')}</li>
            <li className="py-2 hover:bg-skugray-800 px-4">{translate('parser.filebar.edit.cut')}</li>
            <li className="py-2 hover:bg-skugray-800 px-4">{translate('parser.filebar.edit.copy')}</li>
            <li className="py-2 hover:bg-skugray-800 px-4">{translate('parser.filebar.edit.paste')}</li>
          </ul>
        </div>
        <div className="relative flex-initial cursor-pointer text-white py-2 px-8 text-sm hover:bg-black" onMouseEnter={fileBarHandleHover}>{translate('parser.filebar.insert')}</div>
        <div className="relative flex-initial cursor-pointer text-white py-2 px-8 text-sm hover:bg-black" onMouseEnter={fileBarHandleHover}>{translate('parser.filebar.parse')}</div>
        <div className="relative flex-initial cursor-pointer text-white text-sm hover:bg-black">
          <div className="py-2 px-8" htmlFor="preferences" onMouseEnter={fileBarHandleHover} onClick={fileBarHandle}>{translate('parser.filebar.preferences')}</div>
          <ul id="preferences" className="absolute z-50 bg-black left-0 w-64">
            <li className="py-2 hover:bg-skugray-800 px-4">{translate('parser.filebar.preferences.settings')}</li>
            <li className="py-2 hover:bg-skugray-800 px-4">{translate('parser.filebar.preferences.theme')}</li>
          </ul>
        </div>
        <div className="relative flex-initial cursor-pointer text-white text-sm hover:bg-black">
          <div className="py-2 px-8" htmlFor="help" onMouseEnter={fileBarHandleHover} onClick={fileBarHandle}>{translate('parser.filebar.help')}</div>
          <ul id="help" className="absolute z-50 bg-black left-0 w-64">
            <li className="py-2 hover:bg-skugray-800 px-4">{translate('parser.filebar.help.about')}</li>
          </ul>
        </div>
        <div className="relative flex-initial cursor-pointer text-white py-2 px-8 text-sm hover:bg-black" onMouseEnter={fileBarHandleHover} onClick={whatsnew}>{translate('parser.filebar.news')}</div>
      </div>
      <div id="ide" className="flex-1 flex flex-row">
        <div id="editor" className="flex-1 flex flex-col">
          <Dropzone onDrop={handleDrop}>
            {({getRootProps}) => (
            <div {...getRootProps()}>
              <ControlledEditor language="Skript" theme="skUnity" editorDidMount={handleEditorDidMount} onChange={handleEditorChange} />
            </div>
            )}
          </Dropzone>
          <div id ="files" className="flex-1 flex flex-row">
            { models.map((model) => (
                <div key={model.id} className={ models.length === 1 && "active cursor-pointer text-white text-xs py-2 border-t-2 border-skured-500 uppercase font-bold rounded-t-lg"
                || editorInstance.current.getModel().uri === model.uri ? "active cursor-pointer text-white text-xs py-2 border-t-2 border-skured-500 uppercase font-bold rounded-t-lg" : "cursor-pointer text-white text-xs py-2 border-t-2 border-skured-500 uppercase font-bold rounded-t-lg"}>
                  <span className="px-8" onDoubleClick={renameModel} onClick={()=>setModel(model.uri)} spellCheck="false">{model.uri}</span>
                  <span className="cursor-pointer w-4 p-2 hover:bg-skugray-800 rounded-tr-lg" onClick={()=>closeModel(model.uri)}><FontAwesomeIcon icon={faTimes} /></span>
                </div>
              )) }
            <div onClick={createModel} className="cursor-pointer text-white text-xs py-2 px-3 border-t-2 border-skured-500 uppercase font-bold rounded-t-lg">
              <FontAwesomeIcon className="text-xs" icon={faPlus} />
            </div>
          </div>
        </div>
        <div id="debug" className="bg-black flex-none text-white lg:w-4/12 p-8 hidden md:block" style={{background: "#0f0f0f"}}>
          {Array.isArray(fileState.errors) ? fileState.errors.map((parsed)=>(
            <p key={parsed.lineNumber}><a className="text-skured-500 hover:text-skured-900" href="#" htmlFor={parsed.lineNumber} onClick={highlightLine}>Line {parsed.lineNumber}:</a> {parsed.line}</p>
          )) : <p>{fileState.errors}</p>}
        </div>
      </div>
      <div id="stats" style={{background: "#0f0f0f"}}>
        <div className="container mx-auto grid grid-cols-2 sm:grid-cols-6 gap-2 py-5">
          <div className="font-bold text-white text-sm uppercase text-center my-auto flex-grow">
            {translate('parser.statusbar.status')} <span className="text-skured-500">{fileState.status}</span>
          </div>
          <div className="font-bold text-skured-500 text-sm uppercase text-center my-auto flex-grow">
            <span className="text-white">{Array.isArray(fileState.errors) ? fileState.errors.length : 0}</span> {translate('parser.statusbar.errors')}
          </div>
          <div className="font-bold text-teal-500 text-sm uppercase text-center my-auto flex-grow hidden md:block">
            <span className="text-white">{fileState.commands}</span> {translate('parser.statusbar.commands')}
          </div>
          <div className="font-bold text-green-500 text-sm uppercase text-center my-auto flex-grow hidden md:block">
            <span className="text-white">{fileState.functions}</span> {translate('parser.statusbar.functions')}
          </div>
          <div className="font-bold text-purple-600 text-sm uppercase text-center my-auto flex-grow hidden md:block">
            <span className="text-white">{fileState.variables}</span> {translate('parser.statusbar.variables')}
          </div>
          <div className="font-bold text-skugray-400 text-sm uppercase text-center my-auto flex-grow cursor-pointer hidden md:block" onClick={parse}>
            Click to Parse
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(Parser)