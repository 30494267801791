import React, { useState, useContext } from 'react'
import Helmet from 'react-helmet'
import { injectIntl } from 'react-intl'
import LazyLoad from '../components/LazyLoad'
import DocItem from '../components/Docs/DocItem'
import SearchInput from '../components/Docs/SearchInput'

import { DocsContext } from '../context/docs'

//Search Engine
import _ from 'lodash'
import stringSimilarity from 'string-similarity'

//CSS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import '../static/docs.scss'

const Docs = (props) => {
  const { docs } = useContext(DocsContext)
  const [activeFilter, setFilter] = useState({ docs:[], loaded:false})

  const translate = (id) => {
    return props.intl.messages[id]
  }

  const filterWithValue = (docs, filter) => {
    if(filter.startsWith('id:')){
      let filterId = filter.substr(3,filter.length)
      props.history.replace('/docs/id/'+filterId)
      return _.filter(docs, (doc) => filterId === doc.id)
    }
    let tolerance = 0
    let filtered = []
    while(filtered.length === 0){
      filtered = _.filter(docs, (doc) => {
        let searchIn = doc.name.split(" ")
        let isReturned = false
        searchIn.forEach( (name) => {
          if(stringSimilarity.compareTwoStrings(name.toLowerCase(), filter.toLowerCase()) > 0.8-tolerance) isReturned = true
        })
        if(stringSimilarity.compareTwoStrings(doc.name.toLowerCase(), filter.toLowerCase()) > 0.6-tolerance) isReturned = true
        return isReturned
      })
      tolerance += 0.1
      if(tolerance === 0.3) break
    }
    return filtered
  }

  const getFilter = (filter) => {
    if(!docs) return
    let docsToFilter = docs
    let categories = []
    let addons = []
    let ids = []
    if(filter.tags.length > 0){
      filter.tags.map((tag) => {
        if(tag.includes('category:')) categories.push(tag.substr(9,tag.length))
        if(tag.includes('addon:')) addons.push(tag.substr(6,tag.length))
        if(tag.includes('id:')) ids.push(tag.substr(3,tag.length))
      })
      if(categories.length > 0) docsToFilter = _.filter(docsToFilter, (doc) => categories.indexOf(doc.type) > -1)
      if(addons.length > 0) docsToFilter = _.filter(docsToFilter, (doc) => addons.indexOf(doc.addon.name) > -1)
      if(ids.length > 0) docsToFilter = _.filter(docsToFilter, (doc) => ids === doc.id)
    }
    if(ids.length > 0){
      let newHistory = _.filter([ids.join('&')],_.size)
      props.history.replace('/docs/id/'+newHistory.join('/'))
    } else {
      let newHistory = _.filter([categories.join('&'), addons.join('&'), filter.value],_.size)
      props.history.replace('/docs/'+newHistory.join('/'))
    }
    
    if(filter.value.length > 0 && filter.value !== 'category:' && filter.value !== 'addon:' && filter.value !== 'id:'){
      docsToFilter = filterWithValue(docsToFilter, filter.value)
    }
    setFilter({...activeFilter, docs:_.sortBy(docsToFilter,'name')})
  }

  if(docs && !activeFilter.loaded){
    setFilter({...activeFilter, docs:_.sortBy(docs, 'name'), loaded:true})
  }

  let searchTitle
  let searchDesc

  return (
  <div id="Docs" className="flex flex-row flex-1 overflow-hidden dark:bg-skugray-800">
    <Helmet>
      <title>{activeFilter.docs.length === 1 ? "skUnity | " + activeFilter.docs[0].name : props.title}</title>
      <meta name="description" content="skUnity Docs" />
      <meta property="og:site_name" content="skUnity"/>
      <meta property="og:title" content={activeFilter.docs.length === 1 ? "skUnity | " + activeFilter.docs[0].name : props.title} />
      <meta property="og:description" content={activeFilter.docs.length === 1 ? activeFilter.docs[0].desc : "skUnity is the biggest and the first documentation site about Skript."} />
    </Helmet>
    <div className="flex-none py-4 bg-skugray-900 overflow-y-auto hidden sm:block" style={{width: "250px"}}>
      {activeFilter.docs && activeFilter.docs.map((doc) =>
        <div key={doc.id} className="hover:bg-skugray-800 cursor-pointer px-5">
          <p className="capitalize text-white py-1 truncate">{doc.name}</p>
        </div>
      )}
    </div>
    <div className="flex flex-col flex-1 overflow-hidden">
      {docs ? (
        <SearchInput filter={getFilter} />
      ) : (
        <div className="w-full text-center text-skugray-500">
          <FontAwesomeIcon className="animate-spin text-5xl" icon={faSpinner} />
          <p className="text-3xl mt-12">Loading docs</p>
        </div>
      ) }
      <LazyLoad className="flex-1 overflow-auto px-4" preLoad={20} offset={1000} >
        {activeFilter.docs && activeFilter.docs.map((doc) =>
          <DocItem key={doc.id} doc={doc} />
        )}
      </LazyLoad>
    </div>
  </div>
  )
}
export default injectIntl(Docs)