import React from 'react'
import Helmet from 'react-helmet'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import '../static/home.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookOpen, faCode, faUsers } from '@fortawesome/free-solid-svg-icons'

const Home = (props) => {
  const translate = (id) => {
    return props.intl.messages[id]
  }

  return (
    <div id="Home" className="flex-1 h-full overflow-auto">
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content="skUnity" />
      </Helmet>
      <div className="bg-skugray-900">
        <div className="container grid grid-cols-1 sm:grid-cols-2 py-32 px-5">
          <div>
            <p className="md:text-6xl font-bold text-white text-5xl w-5/6 sm:w-full lg:w-5/6 ">Everything about <span className="font-semibold text-skured-500">Skript</span>.</p>
          </div>
          <div className="w-full py-4 text-center">
            <p className="text-white text-2xl py-2">The <span className="font-semibold text-skured-500">biggest</span> community</p>
            <p className="text-white text-2xl py-2"><span className="font-semibold text-skured-500">86</span> addons enlisted</p>
            <p className="text-white text-2xl py-2">Exclusive <span className="font-semibold text-skured-500">tools</span> developed for you</p>
          </div>
        </div>
      </div>
      <div className="container my-32 px-5 grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <p className="md:text-5xl font-bold text-skugray-900 text-4xl w-full">Get started</p>
          <p className="md:text-lg text-skugray-900 text-md w-full">Get the best Skript experience with all the information and the latest updates of every addon.
          The skUnity Documentation is the best place to find exactly what you need to complete your scripts.</p>
        </div>
        <div className="flex items-center text-white relative">
          <Link to="/skript" className="sku-btn border-skured-500 after after:bg-skured-500 mx-auto">Get Skript</Link>
        </div>
      </div>
      <div className="container my-6 px-5">
        <p className="md:text-4xl font-bold text-skugray-900 text-3xl w-full">What's popping?</p>
        <p className="md:text-lg text-skugray-900 text-md w-full">Welcome to skUnity! We're the central hub of everything Skript. Providing a home for the Skript community and providing many unique services, tools and resources. </p>
      </div>
      <div id="whyus" className="container px-5 pb-32 flex grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        <div className="m-2">
          <div className="bg-skugray-100 h-full p-12">
            <FontAwesomeIcon className="text-3xl text-skured-500" icon={faBookOpen} />
            <p className="mt-12 mb-2 font-bold text-skugray-900 text-xl">Largest Documentation</p>
            <p className="text-skugray-900">More addons than anywhere else use skUnity. We've got everything you need</p>
          </div>
        </div>
        <div className="m-2">
          <div className="bg-skugray-100 h-full p-12">
            <FontAwesomeIcon className="text-3xl text-skured-500" icon={faCode} />
            <p className="mt-12 mb-2 font-bold text-skugray-900 text-xl">Unique tools</p>
            <p className="text-skugray-900">The skUntiy Parser is one of a kind and lets you get Skripting quicker</p>
          </div>
        </div>
        <div className="m-2">
          <div className="bg-skugray-100 h-full p-12">
            <FontAwesomeIcon className="text-3xl text-skured-500" icon={faUsers} />
            <p className="mt-12 mb-2 font-bold text-skugray-900 text-xl">Biggest community</p>
            <p className="text-skugray-900">The biggest Skript community around. Connect with like minded people</p>
          </div>
        </div>
      </div>
    </div>
  )
}
export default injectIntl(Home)